import React from "react";
import { Link } from "gatsby";
import { Button, Container } from "../components/Shared";

function NotFound() {
  return (
    <section className="min-h-screen">
      <Container className="min-h-screen">
        <div className="flex min-h-screen pb-44 pt-44">
          <div className="mx-auto flex flex-col justify-center items-center text-center space-y-6">
            <div className="font-serif text-7xl lg:text-9xl font-bold text-red-500 tracking-widest leading-normal">
              4<span className="text-white">0</span>4
            </div>
            <div className="space-y-4">
              <h1 className="font-bold text-xl">Page not found</h1>
              <p className="opacity-70 mx-auto max-w-sm">
                Sorry the page you are looking for does not exist or has been
                moved.
              </p>
            </div>
            <Link to="/" className="flex space-x-6">
              <Button>go home</Button>
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default NotFound;
